import React from 'react';

import styles from './PressList.module.css';

function PressList({ items }) {
  return (
    <div>
      {items.map(item => (
        <article className={styles.article} key={item.id}>
          <h2 className={styles.headline}>
            <a href={item.url}>
              {item.title}
            </a>
          </h2>

          {item.image && (
            <img className={styles.image} alt={item.title} src={item.image} />
          )}

          <div className={styles.excerpt}>{item.excerpt}</div>
          <a className={styles.moreLink} href={item.url}>
            read full article
          </a>
        </article>
      ))}
    </div>
  );
}

export default PressList;
