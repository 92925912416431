import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/Layout';
import PressList from '../components/PressList';

function PressPage({ data }) {
  const items = get(data, 'allContentfulPress.edges', []).map(item => ({
    ...item.node,
    excerpt: get(item, 'node.excerpt.excerpt', ''),
    image: get(item, 'node.image.file.url', null),
  }));

  return (
    <Layout>
      <h1>press</h1>
      <PressList items={items} />
    </Layout>
  );
}

export default PressPage;

export const pageQuery = graphql`
  query PressQuery {
    allContentfulPress(sort: { order: DESC, fields: published }) {
      edges {
        node {
          id
          title
          url
          published
          excerpt {
            excerpt
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
